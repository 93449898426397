import { render, staticRenderFns } from "./PlayerLink.vue?vue&type=template&id=5e80a0d4&scoped=true"
import script from "./PlayerLink.vue?vue&type=script&lang=js"
export * from "./PlayerLink.vue?vue&type=script&lang=js"
import style0 from "./PlayerLink.vue?vue&type=style&index=0&id=5e80a0d4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e80a0d4",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QResizeObserver});
